<style lang="scss" scoped>
.image-viewer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
        transition: .5s;
        transform-origin: 50% 50%;
        max-height: 100%;
        max-width: 100%;
    }
}

.image-viewer__actions {
    position: fixed;
    bottom: 40px;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 20px;
    padding: 5px 16px;

    i {
        font-size: 30px;
        color: #fff;
        margin: 0 10px;
        cursor: pointer;
    }
}
</style>

<template>
    <div class="image-viewer" :style="frameStyle">
        <img ref="image" :src="imageUrl" v-drag :style="imgStyle"/>
        <div class="image-viewer__actions" @click="handelControlClick">
            <i class="el-icon-zoom-out" data-index="0"></i>
            <i class="el-icon-zoom-in" data-index="1"></i>
            <i class="el-image-viewer__actions__divider"></i>
            <i class="el-icon-full-screen" data-index="2"></i>
            <i class="el-image-viewer__actions__divider"></i>
            <i class="el-icon-refresh-left" data-index="3"></i>
            <i class="el-icon-refresh-right" data-index="4"></i>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">

export default {
    name: 'previewImage',
    //定义模版数据
    data() {
        return {
            imgStyle: null,
            frameStyle: null
        }
    },
    //计算属性
    computed: {
        imageUrl() {
            return this.$route.params.url
        }
    },
    directives: {
        //自定义拖动
        drag(el) {
            let oDiv = el;   //当前元素
            oDiv.onmousedown = function (e) {
                e.preventDefault();
                let bw = document.body.clientWidth;
                let bh = document.body.clientHeight;
                //鼠标按下，计算当前元素距离可视区的距离
                let disX = e.clientX - oDiv.offsetLeft;
                let disY = e.clientY - oDiv.offsetTop;
                // 计算两边坐标
                document.onmousemove = function (e) {
                    let l = 0, t = 0;
                    // 拖动边界
                    if (e.clientX >= bw) {
                        l = bw - disX;
                    } else if (e.clientX <= 0) {
                        {
                            l = 0 - disX;
                        }
                    } else {
                        l = e.clientX - disX;
                    }
                    if (e.clientY >= bh) {
                        t = bh - disY;
                    } else if (e.clientY <= 0) {
                        t = 0 - disY;
                    } else {
                        t = e.clientY - disY;
                    }
                    //移动当前元素
                    oDiv.style.left = l + 'px';
                    oDiv.style.top = t + 'px';
                };
                // 鼠标停止移动时，事件移除
                document.onmouseup = function () {
                    document.onmousemove = null;
                    document.onmouseup = null;
                };
            };
        }
    },
    //主件被加载完成
    mounted: function () {
        this.img = {
            rotate: 0,
            scale: 1
        }
    },
    //定义事件方法
    methods: {
        handelControlClick(e) {
            if (e && e.srcElement && e.srcElement.dataset) {
                const {index} = e.srcElement.dataset;
                switch (parseInt(index)) {
                    case 0:
                        if (this.img.scale > .5)
                            this.img.scale -= .5;
                        break;
                    case 1:
                        this.img.scale += .5;
                        break;
                    case 2:
                        this.img = {
                            rotate: 0,
                            scale: 1
                        }
                        break;
                    case 3:
                        this.img.rotate -= 90;
                        break;
                    case 4:
                        this.img.rotate += 90;
                        break;
                }
                let {scale, rotate} = this.img;
                const {width, height} = this.$refs['image'];
                let [fWidth, fHeight] = [width, height];
                if ((rotate / 90) % 2 !== 0 && fWidth > fHeight)
                    [fWidth, fHeight] = [height, width];
                fWidth = fWidth * scale;
                fHeight = fHeight * scale;
                this.frameStyle = `{width:${fWidth}px;height:${fHeight}px;`
                this.imgStyle = `{transform: rotate(${rotate}deg) scale(${scale});-ms-transform: rotate(${rotate}deg) scale(${scale});-webkit-transform: rotate(${rotate}deg) scale(${scale});}`
            }
        }
    },
    //监听模版变量
    watch: {}

}
</script>

<style scoped lang="scss">
.image-viewer {
    position: relative;
    height: 100%;

    img {
        position: absolute;
        top: 20px;
        right: 0;
        left: 20px;
        bottom: 0;
    }
}
</style>
